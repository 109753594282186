.Thankyou {
    //@include bg-circles;

    color: $white;


    //line-height: calc(35/30 * 100%);

    //padding-top: 130px;
    //padding-bottom: 93px;

    padding: 80px 0 40px;



    p {
        //line-height: 35px;
    }

    p:last-of-type {
        margin-bottom: 0;
    }

    &__Title {
        font-size: 30px;
        font-family: $bold;
        margin-bottom: 30px;

        p {
            line-height: 35px;
        }
    }

    &__Body,
    &__Title,
    .PageContent {
        padding: 0 16px;
    }

    .PageContent {
        //font-family: $body;
        //font-size: 16px;
    }

}

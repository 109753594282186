.DetailsScoreSection {

    &__Icons {
        display: flex;
        justify-content: space-around;
        max-width: 380px;
        margin: 0 auto 30px;
        //padding: 0 15px;
    }

    &__Icon {
        width: 49%;
        padding: 0 10px;

        h4 {
            font-family: $body;
            font-size: 14px;
            margin-bottom: 5px;

        }

        img {
            margin-bottom: 0;
            max-width: 160px;
            width: 100%;
        }

        p {
            //font-family: $bold;
            margin: 0;
        }

        &:only-child {
            width: 100%;
        }

    }
}

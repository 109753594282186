@font-face {
    font-family: "reith-sans-reg";
    src: url("../fonts/BBCReithSans_W_Rg.eot"),
         url("../fonts/BBCReithSans_W_Rg.woff2"),
         url("../fonts/BBCReithSans_W_Rg.woff");
}

@font-face {
    font-family: "reith-sans-light";
    src: url("../fonts/BBCReithSans_W_Lt.eot"),
         url("../fonts/BBCReithSans_W_Lt.woff2"),
         url("../fonts/BBCReithSans_W_Lt.woff");
}

@font-face {
    font-family: "reith-sans-bold";
    src: url("../fonts/BBCReithSans_W_Bd.eot"),
         url("../fonts/BBCReithSans_W_Bd.woff2"),
         url("../fonts/BBCReithSans_W_Bd.woff");
}

@font-face {
    font-family: "reith-outline";
    src: url("../fonts/icomoon.eot"),
        url("../fonts/icomoon.ttf"),
        url("../fonts/icomoon.woff");
}

$font-outline: "reith-outline";
$bold: "reith-sans-bold";
$header: $bold;
$body: "reith-sans-light";
$regular: "reith-sans-reg";

.Intro {
    header,
    main {
        padding: 30px 16px;
    }

    header {
        color: $white;
    }

    &__HeaderContent {
        margin-bottom: 40px;
    }

    &__Title {
        font-size: 30px;
        line-height: calc(37/30 * 100%);
        margin-bottom: 15px;
    }

    &__Main {
        background: $white;
        //padding-top: 42px;
        //padding-bottom: 42px;
        padding: 42px 16px;
    }

    &__Buttons {
        margin: 0 auto 43px;
        max-width: 374px;

        .Btn:last-child {
            margin-bottom: 0;
        }
    }

    &__Reminder {
        background: $light-grey;
        padding: 30px 16px;

        h3 {
            font-size: 18px;
            margin-bottom: 10px;
        }

        p {
            margin-bottom: 0;
        }
    }
}

.NumberCounter {
    @include cf();

    margin: 0 auto;
    //width: 600px;

    div {
        float: left;
    }

    p {
        margin-bottom: 0;
    }

    &__Counter {
        width: 25%;
        text-align: center;
        position: relative;

        font-family: $font-outline;

        &::after {
            font-family: $font-outline;
            content: ":";
            font-size: 24px;
            position: absolute;
            right: -5px;
            top: 0;
            //transform: translateY(50%);
            //font-weight: bold;
        }

        .number {
            //height: 80px;
            font-size: 30px;
            //font-weight: $fontWeightTitle;
            display: inline-block;
            margin-right: -5px;
            margin-left: -5px;
            line-height: 23px;
        }

        &:last-of-type {
            &:after {
                display: none;
            }
        }

        .label {
            //font-weight: $fontWeightTitle;
            font-family: $bold;
            //margin-top: 5px;
            font-size: 12px;
        }
    }

    &--reminder {
        .NumberCounter__Counter {
            .label {
                font-size: 12px;
            }
        }
    }

    &--results {

        max-width: 290px;

        .NumberCounter__Counter {
            //font-family: $bold;
            //color: $patriarch;

            .number {
                margin-right: 0;
                margin-left: 0;
            }

            &::after {
                //font-family: $bold;
            }

            .label {
                font-size: 12px;
            }
        }
    }

}

.Btn {
    background: $white;
    border: 0;
    border-radius: 3px;
    color: $patriarch;
    cursor: pointer;
    display: block;
    font-family: $bold;
    font-size: 20px;
    height: 44px;
    text-align: center;
    line-height: 44px;
    margin-bottom: 15px;
    position: relative;
    width: 100%;
    text-decoration: none;
    transition: background-position .5s ease;

    &--purple-border {
        background-image: $pink-grad;
        background-size: 200% auto;
        z-index: 0;

        &:hover {
            background-position: right center;
            color: $pink;
        }

        &:hover::after {
            margin: 2px;
        }

        &::after {
            background-color: $white;
            border-radius: 3px;
            content: "";
            display: block;
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            margin: 1px;
            z-index: -1;
        }

        &[disabled] {
            background: $disabled;
            color: $disabled;
        }
    }

    &--red {
        background-image: $pink-grad;
        background-size: 200% auto;
        color: $white;

        &:hover {
            background-position: right center;
        }

        &[disabled] {
            background: $disabled;
        }
    }

    &--white {
        &:hover {
            background-color: transparentize($white, 0.4);
        }

        &:active {
            background-color: transparentize($white, 0.9);
            border: 1px solid $white;
            color: $white;
        }

        html.a11y-show-focus &:focus {
            border: 1px solid $patriarch;
            box-shadow: 0 0 5px 3px $white;
        }
    }
}

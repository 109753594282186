.root {
    //display: flex;
    //flex-direction: column;
    height: 100%;
    min-height: 100%;
}

.App {
    background: $purple-grad1;
    @include animated-grad;
    text-align: center;
    width: 100%;

    height: auto;
    min-height: 100%;
    display: flex;
    //flex: 1;
    flex-direction: column;
    justify-content: space-between;

    &.bg-disable {
        animation: none;
    }

    .Main {
        //flex: 1;
    }

    .Footer {
        //flex: 0;
    }
}

.taster-offsite-panel {
    position: fixed;
    right: 0;
    top: 0;
    width: 56px;
    z-index: 10;
    opacity: 1;
    transition: .5s opacity ease, .5s z-index ease, .5s right ease;

    &.is_hidden {
        opacity: 0;
        z-index: -1;
        right: -56px;
    }
}

.InformedConsent {
    @include bg-circles;

    &__Header {
        color: $white;
        //height: 300px;
        padding: 80px 16px;
    }

    &__Body {
        background-color: $white;
        text-align: left;
        padding: 30px 16px;
    }
}

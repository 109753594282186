.Progress {
    background-color: transparentize($dark-grey, .8);
    color: $white;

    height: 50px;
    padding: 16px 0;

    @include until-tablet {
        padding-top: 14px;
    }

    > .container {
        padding: 0 30px;
    }

    &__Container {
        background-color: transparentize($dark-grey, .5);
        border-radius: 3px;
        display: inline-block;
        height: 2px;
        width: 80%;
        float: left;
        margin-top: 11px;
    }

    &__Bar {
        background-color: $white;
        border-radius: 3px;
        box-shadow: 0 0 10px 1px $white;
        height: 100%;
        transition: width .5s ease;
    }

    &__No {
        width: 10%;
    }

    &__Details {
        font-size: 16px;
        float: right;

        @include until-tablet {
            font-size: 12px;
        }
    }

    // Page specific styles
    .Questionnaire--thankyou & {
        display: none;
    }
}


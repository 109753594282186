.ScoreSection {
    margin-bottom: 40px;

    &__Divider {
        background: $white;
        display: block;

        height: 1px;
        width: 25%;

        margin: 0 auto 31px;

        opacity: 0.5;
    }

    &__Title {
        margin-bottom: 19px;
        font-size: 18px;
    }

    &__Icons {
        display: flex;
        justify-content: space-around;
        max-width: 380px;
        margin: 0 auto 20px;
        //padding: 0 15px;
    }

    &__Icon {
        width: 49%;
        padding: 0 10px;

        h4 {
            font-family: $body;
            font-size: 12px;
            margin-bottom: 0;
        }

        img {
            margin-bottom: 0;
            max-width: 160px;
            width: 100%;
        }

        p {
            font-family: $bold;
            margin: 0;
            font-size: 18px;
            margin-top: -5px;
        }

        &--next {
            opacity: 0.3;
        }

        &:only-child {
            //width: 100%;
        }

    }

}

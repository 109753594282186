$desktop: 600px;
$tablet: 420px;

@mixin desktop() {
    @media (min-width: $desktop) {
        @content;
    }
}

@mixin tablet() {
    @media (min-width:$tablet) and (max-width: $desktop - 1) {
        @content;
    }
}

@mixin until-desktop {
    @media (max-width: $desktop - 1) {
        @content;
    }
}

@mixin until-tablet {
    @media (max-width: $tablet) {
        @content;
    }
}

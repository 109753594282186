.ExternalPage {

    color: $white;
    height: auto;


    .App {
        text-align: left;
        padding: 40px 0;
    }

    h1 {
        margin-bottom: 40px;
        text-align: center;
    }

    h2 {
        margin-bottom: 20px;
    }

    a {
        color: $white;
    }
}

.SingleSelect,
.MultiSelect {
    margin-bottom: 20px;
    text-align: left;

    &__Id {
        margin-right: 10px;
    }

    &__QuestionWrapper {
        //margin-bottom: 10px;
        position: relative;
    }

    &__QuestionWrapper--expl {
        .SingleSelect__Question,
        .MultiSelect__Question {
            padding-right: 35px;
        }
    }

    &__ExpButton {
        position: absolute;
        top: -4px;
        right: 0;
    }

    &__Question {
        display: inline-block;
        font-family: $bold;
        //line-height: calc((30/16) * 100%);
        margin: 0 0 5px;
        // margin: 0;

        @include until-tablet {
            //padding-right: 35px;
        }
    }

    &__Answers {
        list-style: none;
        // margin: 0 0 20px;

        margin: 0;
        padding: 5px 0 0;
    }

    &__Label {
        @include cf;
    }

    &__Answer {
        background-color: $light-grey;
        border-radius: 3px;
        margin-bottom: 2px;

        &:last-child {
            margin-bottom: 0;
        }

        &:hover {
            background: $mid-grey;
        }

    }

    .error {
        display: none;
    }

    &--error {
        background: $error;
        padding-top: 23px;
        padding-bottom: 23px;

        .SingleSelect__Answer,
        .MultiSelect__Answer {
            background-color: $white;
            border: 1px solid $error-red;
        }

        .LikertScale .SingleSelect__Answer {
            background-color: initial;
            border: 0;
        }

        .error {
            display: block;
        }
    }

    &__Error {
        color: $error-text;
        font-size: 14px;
    }

    &__ErrorLabel {
        font-family: $bold;
    }

    html.a11y-show-focus & input:focus + label {
        background: $mid-grey;
        box-shadow: none;
    }

    input:checked + label,
    html.a11y-show-focus & input:checked + label {
        background: $purple-grad;
        color: $white;

        .pre {
            background: $purple-grad1;
            border-width: 3px;
            border-color: $white;
        }
    }



}

.SingleSelect--singleSelect {
    .SingleSelect {
        &__Label {
            border-radius: 3px;
            cursor: pointer;
            display: block;
            height: 100%;
            line-height: 40px;
            position: relative;
            width: 100%;

            transition: background .2s linear, color .2s linear;

            .pre {
                background-color: $white;
                border: 1px solid $likert-grey;
                display: inline-block;
                width: 26px;
                height: 26px;
                margin: 0 10px;
                cursor: pointer;
                border-radius: 50%;
                top: 50%;
                position: absolute;
                transition: border-width linear .2s;
                transform: translateY(-50%);
            }

            .post {
                display: block;
                float: right;
                line-height: 21px;
                padding: 14px 10px 14px 46px;
                width: 100%;
            }
        }


    }

    &.SingleSelect--error {
        .SingleSelect__Label .pre {
            border-color: $mid-grey;
            border-width: 2px;
        }

        // .SingleSelect__Answer {
        //     border-color: none;
        // }
    }
}

.SingleSelect--yesno {
    .SingleSelect {
        &__Question {
            // margin-bottom: 10px;
        }

        &__Answers {
            height: 55px;
        }

        &__Answer {
            //background: initial;
            background-color: transparent;
            border: 0;
            display: inline-block;
            margin: 0;
        }

        &__Label {
            border: 1px solid $likert-grey;
            border-radius: 100%;
            cursor: pointer;
            height: 48px;
            width: 48px;
            display: block;
            line-height: 44px;
            margin-right: 11px;
            text-align: center;
            margin-top: 1px;
        }


    }

    .SingleSelect__Answer:hover {
        .SingleSelect__Label {
            border: 2px solid $purple-grad1;
            margin-top: 0;
        }

        input:checked + label {
            border: 2px solid $purple-grad1;
            margin-top: 1px;
        }
    }

    html.a11y-show-focus & input:focus + label {
        background: transparent;
        color: $dark-grey;
    }

    input:checked + label {
        border: 2px solid $purple-grad1;
        margin-top: 1px;
    }

    &.SingleSelect--error .SingleSelect__Label {
        background-color: $white;
        border-color: $error-red;
    }

    html.a11y-show-focus & input:focus + label {
        @include focus-outline;
        background: $purple-grad;
        color: $white;
    }
}

.SingleSelect--likert {
    &.SingleSelect {
        margin-bottom: 30px;

        &__Question {
            margin-bottom: 10px;
        }
    }
}

.LikertScale {
    display: flex;
    justify-content: space-between;

    li {
        background-color: transparent;
        //background: initial;
        display: inline-block;
        position: relative;

        @for $i from 1 through 7 {
            &:nth-child(#{$i}) .SingleSelect__Label .pre::after {
                content: '#{$i}';
                position: absolute;
                left: 50%;
                top: -1px;
                transform: translateX(-50%);
            }
        }

        &:first-child .post {
            left: 0;
            text-align: left;
        }

        &:last-child .post {
            left: auto;
            right: 0;
            text-align: right;
        }

        &::after {
            content: "";
            display: block;
            position: absolute;
            width: 20vw;
            height: 1px;
            background: $likert-grey;
            left: 90%;
            top: 35%;
            z-index: 1;

            @include desktop {
                width: 100px;
            }

        }

        &:last-child::after {
            content: none;
        }

        &:hover {
            .SingleSelect__Label {
                .pre {
                    //background: red;
                    border: 2px solid $purple-grad1;

                    &::after {
                        top: -2px;
                    }
                }
            }

            input:checked + label {
                .pre::after {
                    top: -1px;
                }
            }
        }

    }

    &--4 li::after {
        width: 25vw;

        @include desktop {
            width: 150px;
        }
    }

    &--7 li::after {
        width: 10vw;

        @include desktop {
            width: 75px;
        }
    }

    label {
        // background: auto;
        display: inline-block;
        font-family: $regular;
        position: relative;
        width: 37px;

        .pre {
            background: $white;
            border: 1px solid $likert-grey;
            border-radius: 100%;
            color: $dark-grey;
            cursor: pointer;
            display: block;
            height: 37px;
            line-height: 36px;
            left: 50%;
            margin-bottom: 10px;
            position: relative;
            transform: translateX(-50%);
            width: 37px;
            z-index: 2;
        }

        .SingleSelect--error & .pre {
            border-color: $error-red;
        }

        .post {
            @include visuallyhidden;
            color: $dark-grey;
            font-size: 12px;
            top: 100%;
            left: -100%;
            width: 300%;
            text-align: center;
            line-height: 1;
            font-family: $bold;
        }
    }

    input:checked + label, html.a11y-show-focus & input:checked + label {
        //background: initial;
        background: transparent;
        color: initial;

        .pre {
            background: $purple-grad;
            border-color: $purple-grad1;
            border-width: 2px;
            color: $white;

        }

        .pre::before {
            @include arrow(14px, 10px, $purple-grad1);
            content: "";
            display: block;
            position: absolute;
            top: 100%;
            left: 50%;
            transform: translateX(-50%);
        }

        .post {
            clip: unset;
            overflow: visible;
        }
    }

    html.a11y-show-focus & input:focus + label {
        background: transparent;
        @include focus-outline;
    }

    .SingleSelect__Answer:hover {
        background: transparent;
    }

}

.MultiSelect {
    &__Answer .CustomCheck + label {
        line-height: 1.4;
        padding: 18px 11px 18px 50px;
    }
}

.CustomCheck {
    position: absolute;
    left: -99999px;

    + label {
        line-height: 44px;
        position: relative;
        padding: 0 11px 0 50px;
        cursor: pointer;
        display: block;

        &::before {
            content: "";
            position: absolute;
            left: 10px;
            width: 26px;
            height: 26px;
            background: $white;
            border: 1px solid $likert-grey;
            border-radius: 3px;

            top: 50%;
            transform: translateY(-50%);
        }

        &::after {
            content: url("../img/tick.svg");
            position: absolute;
            left: 15px;
            opacity: 0;
            top: 50%;
            transform: scale(.5) translateY(-100%);
            transition: all .2s ease;

            width: 18px;
            height: 13px;
        }
    }


    &:checked + label::after {
        opacity: 1;
        transform: scale(1) translateY(-80%);
    }
}


.CustomRadio {
    @include visuallyhidden;
}

$white: #fff;
$grey: #ccc;
$dark-grey: #262336;
$black: #000;

$error-red: #d0021b;
$error: transparentize($error-red, .9);
$error-text: #e60000;

$blue: #5066cb;
$royal-purple: #667eea;
$red: #c03672;
$pink: #b62dab;
$patriarch: #820b79;
$purple-heart: #582297;

$likert-grey: #aaa7ba;

$light-grey: #e5e4e9;
$mid-grey: #cfcdd9;
$disabled: #8c8a99;

$pink-grad1: #ac4589;
$pink-grad2: #a446a1;

$purple-grad1: #5b63b1;
$purple-grad2: #4a6fc3;

$pink-grad: linear-gradient(134deg, $pink-grad1 0%, $pink-grad2 51%, $royal-purple 100%);
$purple-grad: linear-gradient(134deg, $purple-grad1 0%, $purple-grad2 51%, $purple-grad1 100%);

$a11y: $patriarch;

$grad-stop: #764ba2;

$feedback-section-bg: transparentize($black, .75);

.LogoHeader {
    //background-color: transparentize($purple-heart, .8);
    // margin-bottom: 30px;
    //padding: 17px 16px;

    &__Container {
        @include cf;
        margin: 0 auto;
        max-width: 260px;
        width: 100%;
    }

    &__Left,
    &__Right {
        width: 45%;
    }

    &__Left {
        float: left;
    }

    &__Right {
        float: right;
    }
}

.Login {

    header,
    main {
        padding: 30px 16px 5px;
    }

    &__Body {
        background-color: $white;
        padding: 31px 16px;
        text-align: left;

        .PageContent {
            margin-bottom: 20px;
        }

        > div > span > div {
            margin-right: -16px;
            margin-left: -16px;

            padding-right: 16px;
            padding-left: 16px;
        }
    }

    &__LoginError {
        margin-right: -16px;
        margin-left: -16px;

        padding: 20px 16px;

        background: $error;

        .TitleContent__Title {
            color: $error-text;
        }
    }
}

.Circles {
    color: $white;
    width: 230px;
    height: 230px;
    margin: 0 auto 30px;
    position: relative;
    top: 0;
    text-align: center;

    &__Text {
        position: relative;
        transform: translateY(-50%);
        top: 50%;
        margin: 0 auto;
        width: 210px;

        h1 {
            font-size: 26px;
        }

        p {
            font-size: 16px;
        }
    }

    &__Circle {
        width: 210px;
        height: 210px;
        background: transparent;
        border: 1px solid $white;
        border-radius: 999px;
        opacity: 0.4;
        position: absolute;
        top: 10px;
        left: 10px;
        transform-origin: 50% 50%;

        &--two {
            animation: rotate 20s linear infinite;
            animation-direction: reverse;
            animation-delay: -10s;
        }

        &--three {
            animation: rotate 20s linear infinite;
            animation-delay: -5s;
        }

        &--four {
            animation: rotate 20s linear infinite;
            animation-direction: reverse;
            animation-delay: -15s;
        }
    }

    &__Sparkles {
        position: absolute;
        top: 10px;
        left: 0;
        right: 0;
        margin: 0 auto;
        width: 210px;
        height: 210px;
    }

    .sparkle {
        width: 0;
        height: 0;
        position: absolute;
        background: $white;
        border-radius: 99px;
        opacity: 0;

        &.on {
            animation: fadeInOut 4s;
            animation-fill-mode: forwards;
        }
    }
}

@keyframes fadeInOut {
    0% {
        opacity: 0;
    }

    50% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

@keyframes rotate {
    from {
        transform: rotate(0deg) translateX(10px);
    }

    to {
        transform: rotate(360deg) translateX(10px);
    }
}

.Footer {
    //height: 110px;
    padding: 30px 19px 26px;

    &__Links {
        display: flex;
        justify-content: center;
        margin-bottom: 20px;
        color: $white;
    }

    &__Link {
        color: $white;
        font-size: 14px;
        text-decoration: none;

        margin-right: 10px;
        margin-left: 10px;

    }

    &__Logo {
        width: 60px;
    }
}

@import "base/normalize";
@import "base/fonts";
@import "base/colours";
@import "base/mixins";
@import "base/defaults";
@import "base/helpers";
@import "base/media";

@import "base/transitions";


// BEM Blocks/Components
@import "blocks/App";
@import "blocks/CookieWarning";
@import "blocks/Progress";
@import "blocks/Buttons";
@import "blocks/Circles";
@import "blocks/DetailsScoreSection";
@import "blocks/LogoHeader";
@import "blocks/ModalWindow";
@import "blocks/NumberCounter";
@import "blocks/PageContent";
@import "blocks/Phase2Reminder";
@import "blocks/QuestionExplanation";
@import "blocks/ResourceContent";
@import "blocks/Section";
@import "blocks/SectionPagination";
@import "blocks/ScoreSection";
@import "blocks/FeedbackDetails";
@import "blocks/FeedbackSection";
@import "blocks/Footer";
@import "blocks/Warning";
@import "blocks/SocialShare";

// Pages
@import "pages/Intro";
@import "pages/InformedConsent";
@import "pages/Questionnaire";
@import "pages/Reminder";
@import "pages/Results";
@import "pages/Thankyou";
@import "pages/Login";
@import "pages/ExternalPage";

// Form Inputs
@import "inputs/DropDown";
@import "inputs/SingleSelect";
@import "inputs/TextEntry";




//.block {
//}
//
//.block__element {
//}
//
//.block--modifier {
//}

.Results {
    //margin-top: 44px;
}

.debugData {

    @include cf();

    text-align: left;
    background: $white;
    padding: 20px;

    border-bottom: 2px solid $dark-grey;

    //position: fixed;
    //top: 50px;
    //left: 50%;
    //transform: translateX(-50%);
    width: 100%;
    z-index: 100;
    color: $dark-grey;

    p {
        margin-bottom: 0;
        clear: left;
    }

    .TextEntry {
        //text-align: center;
        margin-bottom: 20px;
        margin-right: 5%;
        float: left;
        width: 45%;

        &:nth-of-type(2n) {
            margin-right: 0;
        }

        label {
            display: none;
        }

        input {
            width: 100%;
        }
    }
}

.debugDataButton.Btn {
    //position: fixed;
    top: 0;
    z-index: 1000;
    margin-bottom: 0;
    background: $grey;
}



.FeedbackDetails {



    .container {
        padding: 30px 16px 0;
    }

    .TitleContent {
        margin-bottom: 30px;
    }

    &__Back {
        margin-bottom: 20px;
        height: 56px;
        //position: fixed;
        &:nth-of-type(2) {
            margin-bottom: 0;
            height: 44px;
        }
    }

    &__Title {
        font-size: 30px;
        line-height: calc(35/30 * 100%);
        margin-bottom: 30px;
        padding: 0 16px;
    }

    &__Details {
        background-color: $feedback-section-bg;
        padding: 33px 16px 1px;
        margin-bottom: 30px;

        a {
            color: $white;
        }
    }

    &__Additional {
        background-color: $white;
        color: $dark-grey;

        text-align: left;

        padding: 33px 0;

        .container {
            padding: 0 16px;
        }
    }

}

.Content {
    &__Title {
        margin-bottom: 20px;
    }

    &__Body {
        margin-bottom: 30px;
        //text-align: left;

        p:last-child {
            margin-bottom: 0;
        }
    }
}

.TitleContent {
    &__Title {
        margin-bottom: 15px;
    }

    &__Body {
        p {
            margin-bottom: 15px;
        }

        p:last-child {
            margin-bottom: 0;
        }
    }
}

.IconContent {
    &__Icon {
        margin-bottom: 10px;
        height: 35px;
        width: 35px;
    }

    &__Title {
        font-size: 22px;
        line-height: calc(37/30 * 100%);
        margin-bottom: 10px;
    }

    &__Body {
        p {
            margin-bottom: 30px;
        }


    }
}

.ListContent {
    &__Body {
        margin-bottom: 30px;

        p:last-child {
            margin-bottom: 0;
        }
    }

    &__List {
        list-style: none;
        padding-left: 0;
        margin-bottom: 30px;
    }
}

.AgreeBox {
    @include cf;
    background-color: $light-grey;
    margin-bottom: 30px;
    padding: 16px;
    position: relative;

    border-radius: 3px;

    .MultiSelect__Answer .CustomCheck + label {
        //transform: translateY(-30%);
        padding: 0;
    }



    .CustomCheck:checked + label {
        background: initial;

        &::before {
            left: 4px;
            border: 2px solid $purple-grad1;
        }

        &:after {
            transform: scale(2) translateY(-50%);
        }
    }

    html.a11y-show-focus & .CustomCheck:focus + label::before {
        @include focus-outline;
    }

    label {
        height: 54px;
        width: 54px;
        overflow: hidden;
        //top: 20px;

        span {
            position: absolute;
            text-indent: 999px;
            display: inline-block;
        }
    }

    .MultiSelect {
        @include cf;
        margin-bottom: 20px;
        display: flex;

        &__Id {
            margin: 0;
        }

        &__Question {
            float: right;
            padding-left: 10px;
            padding-right: 0;
            //width: 75%;
            line-height: 23px;
            order: 2;
            align-self: center;
        }

        &__Answers {
            padding-top: 0;
            float: left;
            order: 1;
        }

        label:before {
            height: 44px;
            width: 44px;
            // top: 0;
            left: 5px;
            //border-width: 2px;
        }

        label:after {
            text-indent: 0;
            top: 25px;
            left: 19px;
        }

        &__Answer:hover {
            background: transparent;

            label:before {
                left: 4px;
                border: 2px solid $purple-grad1;
            }
        }


    }

    .Btn {
        margin-bottom: 0;
    }

}

.Social {
    margin-bottom: 30px;
    padding: 0 32px;

    &__Title {
        font-size: 18px;
        margin-bottom: 15px;
    }

    &__Icons {
        display: flex;
        justify-content: center;
    }

    &__Icon {
        background-repeat: no-repeat;
        background-size: contain;
        border: 0;
        cursor: pointer;

        text-indent: 9999px;
        overflow: hidden;
        white-space: nowrap;
        height: 52px;
        width: 52px;

        margin: 0 5px;

        &--fb {
            background-image: url("../img/icons/facebook.png");
        }

        &--tw {
            background-image: url("../img/icons/twitter.png");
        }
    }
}


.Section {
    &__Header {
        color: $white;
        //height: 260px;
        // margin-bottom: 30px;
        padding: 50px 16px 80px;
    }

    &__Questions {
        background: $white;
        margin: 0 auto;
        max-width: 600px;

        //padding: 30px 16px 16px 16px;
        //padding: 30px 0 16px;

        padding: 34px 0 20px;

        > span > div {
            // padding: 30px 16px 16px;
            // padding: 0 16px;
            // margin-bottom: 20px;
            padding-right: 16px;
            padding-left: 16px;
            //&:last-of-type {
                //margin-bottom: 0;
            //}
        }

    }

    &__Number {
        border: 1px solid $white;
        border-radius: 100%;
        display: inline-block;
        font-size: 14px;
        line-height: 28px;

        height: 30px;
        width: 30px;

        margin-bottom: 10px;
    }

    &__Title {
        font-family: $bold;
        font-size: 30px;
        line-height: calc((37/30) * 100%);
    }

    &__LikertKey {
        list-style: none;
        // padding: 0;
        margin: 0 0 20px;
        text-align: left;
        padding: 0 16px;
    }

    &__LikertRow {
        span {
            font-family: $bold;
        }
    }

    &__Description {
        // padding: 0 19px 20px;
        margin-bottom: 20px;
        text-align: left;

        p:last-of-type {
            margin-bottom: 0;
        }
    }
}

.ResourceContent {
    margin-bottom: 32px;
    position: relative;
    width: 45%;

    &__Image {
        background: $light-grey;
        margin-bottom: 14px;
        height: 124px;
        width: 100%;
        border-radius: 3px;

        img {
            top: 50%;
            position: relative;
            transform: translate(0, -50%);
            max-width: 100%;
        }
    }

    &__Title {
        color: $patriarch;
        font-family: $bold;
        font-size: 18px;
        text-align: left;
        margin-bottom: 10px;
    }

    &__Body {
        text-align: left;
        //flex: 0 1 auto;
        height: 180px;
    }

    @include until-tablet {
        width: 100%;

        &__Body {
            height: auto;
        }
    }

    @include desktop {
        &__Body {
            height: 140px;
        }
    }

    &__Button {
        bottom: 0;
        margin: 0;
        // position: absolute;
        //align-self:flex-end;
    }
}

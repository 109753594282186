.SocialShare {
    background: $feedback-section-bg;
    color: $white;
    padding: 20px 16px;

    &__Title {
        font-size: 18px;
        margin-bottom: 15px;
    }

    &__Icons {
        display: flex;
        justify-content: center;
    }

    &__Icon {
        background-repeat: no-repeat;
        background-size: contain;
        border: 0;
        cursor: pointer;

        text-indent: 9999px;
        overflow: hidden;
        white-space: nowrap;
        height: 52px;
        width: 52px;

        margin: 0 5px;

        background-color: transparent;

        &--fb {
            background-image: url("../img/icons/facebook.svg");
        }

        &--tw {
            background-image: url("../img/icons/twitter.svg");
        }
    }
}

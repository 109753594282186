.Results {
    @include bg-circles;
    color: $white;

    &__Header {
        margin-bottom: 25px;
        padding: 62px 3px 0;

        p:last-child {
            margin: 0;
        }
    }

    &__Title {
        margin-bottom: 20px;
    }

    &__Body {
        margin-bottom: 30px;
    }

    &__Subtitle,
    &__Warning {
        font-family: $bold;
    }

    &__Overview {
        //margin-bottom: 60px;
        padding: 0 16px;
    }

    &__Resources {
        background: $white;
        color: $dark-grey;
        padding: 44px 19px 0;
        position: relative;
        //top: -1px;
    }
}

.Resources {
    &__Title {
        margin-bottom: 30px;
        text-align: left;
    }

    &__Content {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }
}

html {
    font-size: 16px;
    height: 100%;
    min-width: 320px;

    *:focus {
        outline: none;
    }

    &.a11y-show-focus *:focus {
        @include focus-outline;
    }


    &.modal-enabled {

        overflow: hidden;
        touch-action: none;

        body {
            overflow: hidden;
            touch-action: none;
        }

        .App {
            overflow: hidden;
            height: 100%;
        }

        .Main {
            overflow: hidden;
        }

        .Footer {
            display: none;
        }

    }

}


body {
    font-family: $body;
    font-size: inherit;
    height: 100%;
    line-height: calc(22/16 * 100%);
    margin: 0;
    color: $dark-grey;

}


* {
    box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: $header;
    margin: 0;
}

h1 {
    font-size: 30px;
    line-height: calc(35 / 30 * 100%);
}

h2 {
    font-size: 28px;
    line-height: calc(34 / 28 * 100%);
}

h3 {
    font-size: 22px;
    line-height: calc(28 / 22 * 100%);
}

h4 {
    font-size: 18px;
    line-height: calc(24 / 18 * 100%);
}

p {
    margin-top: 0;
    margin-bottom: 20px;
    line-height: 23px;
}

a {
    color: $red;
    text-decoration: underline;
}

b {
    font-family: $bold;
}

.container {
    width: 100%;
    margin: 0 auto;
    max-width: 600px;
}

input,
select {
    font-family: $body;
    font-size: 16px;
    line-height: calc((16/20) * 100%);
}

.hidden {
    display: none;
}

.vh {
    @include visuallyhidden;
}




.SectionPagination {
    background: $white;
    padding: 0 16px 30px;

    &__Buttons {
        display: flex;
        justify-content: space-between;

        button {
            max-width: 150px;
            width: 48%;
        }
    }

    &__Error {
        background: $error;
        font-family: $bold;
        margin-bottom: 20px;
        padding: 17px 10px;
        position: relative;

        button {
            //background: transparent;
            margin: 0;
        }

        &::after {
            top: 100%;
            //left: 75%;
            right: 55px;
            border: solid transparent;
            content: " ";
            height: 0;
            width: 0;
            position: absolute;
            pointer-events: none;
            border-color: transparent;
            border-top-color: $error;
            border-width: 20px;
            margin-left: -20px;
        }

    }

    // Page specific styles
    .Questionnaire--thankyou &,
    .Questionnaire--thankyou2 &,
    .Questionnaire--phase2intro & {
        background: transparent;

        .SectionPagination__Buttons {
            flex-wrap: wrap-reverse;

            button {
                max-width: none;
                width: 100%;
            }
        }

        .Btn--red {
            background: $white;
            color: $patriarch;
            //width: 100%;

        }

        .Btn--purple-border {
            background: transparent;
            border: 1px solid $white;
            color: $white;

            &::after {
                background: transparent;
            }
        }
    }

    .Questionnaire--phase2intro & {
        .Btn:nth-child(1) {
            display: none;
        }
    }
}



.Reminder {
    @include bg-circles;

    &__Header {
        color: $white;
        padding: 80px 16px 40px;
        font-size: 30px;
        font-family: $bold;
        //line-height: calc((35/30) * 100%);
        p {
            line-height: 35px;
        }
    }

    &__Body {
        background: $white;
        padding: 31px 16px 39px;

        .TitleContent {
            text-align: left;
            margin-bottom: 30px;
        }
    }

    &__Divider {
        height: 40px;
        line-height: 40px;
        margin: 0 0 4px;
        position: relative;
        font-family: $bold;

        span {
            height: 100%;
            background-color: $white;
        }

        &:before,
        &:after {
            background: $mid-grey;
            content: "";
            position: absolute;
            height: 1px;
            top: 50%;
            width: 45%;
        }

        &:before {
            left: 0;
        }

        &:after {
            right: 0;
        }

        &--large {
            margin-bottom: 16px;
        }

    }

    &__Button {
        margin-bottom: 30px;

        &:last-child {
            margin: 0;
        }
    }

    .TextEntry {
        margin-bottom: 16px;
    }

    input {
        width: 100%;
    }
}

.FeedbackSection {
    background: transparentize($black, .75);
    margin-bottom: 20px;
    padding: 30px 16px;

    border-radius: 3px;

    &__Title {
        margin-bottom: 30px;
        font-size: 22px;
    }

    &__Button {
        margin: 0 auto;
        max-width: 256px;
    }
}

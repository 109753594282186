.ModalWindow {
    position: fixed;
    width: 100%;
    height: 100%;
    background: rgba(1, 0, 0, .4);
    padding: 20px 16px;
    z-index: 999;

    &__Content {
        background: $white;
        font-family: $bold;
        font-size: 18px;
        padding: 24px 20px 20px;
        position: relative;
        top: 50%;
        transform: translate(0, -50%);
    }
}

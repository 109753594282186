.CookieWarning {
    background: $white;
    padding: 10px;
    text-align: left;
    z-index: 999;

    &__Title {
        font-size: 16px;
    }

    &__Message {
        font-size: 13px;
        margin: 0 0 5px;
    }

    &__Links {
        font-size: 12px;
        list-style: none;
        margin: 0 0 20px;
        padding: 0;
    }

    &__Link {
        display: inline-block;
        margin-right: 10px;
        padding-right: 10px;
        position: relative;

        &::after {
            content: "|";
            display: block;
            position: absolute;
            right: 0;
            top: 0;
        }

        &:last-child {
            margin-right: 0;
            padding-right: 0;

            &::after {
                content: "";
            }
        }
    }
}

.DropDown {
    margin-bottom: 20px;
    position: relative;
    text-align: left;

    &__Id {
        margin-right: 10px;
    }

    &__Label {
        display: block;
        font-family: $bold;
        //line-height: calc((30/16) * 100%);
        margin: 0 0 5px;
    }

    &__Select {
        cursor: pointer;
        height: 44px;

        &::before {
            content: url("../img/icons/dropdown.svg");
            line-height: 48px;
            pointer-events: none;
            position: absolute;
            right: 30px;
            width: 24px;
        }

        select {
            cursor: pointer;
        }



    }

    .error {
        display: none;
    }

    &--error {
        background: $error;
        padding-top: 23px;
        padding-bottom: 23px;

        .error {
            display: block;
        }

        .DropDown__Select select {
            border-color: $error-red;
        }
    }

    &__Error {
        color: $error-text;
        font-size: 14px;
    }

    &__ErrorLabel {
        font-family: $bold;
    }

    select {
        appearance: none;
        background-color: $white;
        border: 1px solid $likert-grey;
        height: 100%;
        line-height: 40px;
        padding: 0 10px;
        width: 100%;

        &.valid {
            border-color: $blue;
        }
    }

    select::-ms-expand {
        display: none;
    }

}

.Phase2Reminder {
    background-color: $feedback-section-bg;
    color: $white;
    padding: 20px 16px 1px;

    font-family: $bold;

    border-radius: 3px;

    margin-bottom: 30px;

    .NumberCounter {
        margin-bottom: 20px;
        font-size: 5px;
    }

    .Btn {
        margin-bottom: 20px;
    }


}

$transition: .35s transform ease;


.Slide.blur {
    filter: blur(5px);
}

.Slide,
.Page {

    //transform: translate(0,0);

    width: 100%;
    min-height: 100%;
    //position: relative;
    top: 0;
    left: 0;
    transform: translate(0, 0);

    &.slide-enter {
        //position: absolute;
        transform: translate(100%, 0);

        &.slide-enter-active {
            //position: absolute;
            transition: $transition;
            transform: translate(0, 0);
        }
    }

    &.slide-leave {
        position: absolute;
        transform: translate(0, 0);

        &.slide-leave-active {
            transition: $transition;
            transform: translate(-100%, 0);
        }
    }
}

.Back .Slide {
    &.slide-enter {
        transform: translate(-100%, 0);

        &.slide-enter-active {
            transition: $transition;
            transform: translate(0, 0);
        }
    }

    &.slide-leave {
        position: absolute;
        transform: translate(0, 0);

        &.slide-leave-active {
            transition: $transition;
            transform: translate(100%, 0);
        }
    }
}

.Results__Details {
    &.slide-enter {
        transform: translate(100%, 0);

        &.slide-enter-active {
            transition: $transition;
            transform: translate(0, 0);
        }
    }

    &.slide-leave {
        position: absolute;
        transform: translate(0, 0);

        &.slide-leave-active {
            transition: $transition;
            transform: translate(100%, 0);
        }
    }
}

.Results__Overview {
    &.slide-enter {
        transform: translate(-100%, 0);

        &.slide-enter-active {
            transition: $transition;
            transform: translate(0, 0);
        }
    }

    &.slide-leave {
        position: absolute;
        transform: translate(0, 0);

        &.slide-leave-active {
            transition: $transition;
            transform: translate(-100%, 0);
        }
    }
}

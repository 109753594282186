.TextEntry {
    margin-bottom: 20px;
    text-align: left;

    &__Id {
        margin-right: 10px;
    }

    &__Label {
        display: block;
        font-family: $bold;
        //line-height: calc((30/16) * 100%);
        margin: 0 0 5px;
        line-height: 23px;
    }

    &__Error {
        color: $error-text;
        font-size: 14px;
    }

    &__ErrorLabel {
        font-family: $bold;
    }

    .error {
        display: none;
    }

    &--error {
        background: $error;
        padding-top: 23px;
        padding-bottom: 23px;

        .error {
            display: block;
        }
    }

    &__QuestionWrapper {
        //margin-bottom: 10px;
        position: relative;
    }

    &__QuestionWrapper--expl {
        .TextEntry__Label {
            padding-right: 35px;
        }
    }

    input {
        border: 2px solid $blue;
        border-radius: 3px;
        height: 44px;
        padding: 0 10px;

        &[value=""],
        &:invalid {
            border: 1px solid $likert-grey;
            // Prevent FF from displaying red ring
            box-shadow: none;
        }

        html.a11y-show-focus &:focus {
            @include focus-outline;
        }
    }

    &.TextEntry--error  input {
        border-color: $error-red;
    }
}

@mixin focus-outline {
    border: 1px solid $white;
    box-shadow: 0 0 5px 3px $a11y;
    outline: 0;
}

@mixin bg-circles {
    background-image: url("../img/header_circles.png");
    background-position: top right;
    background-repeat: no-repeat;
    // background-size: contain;
    background-size: 320px 340px;
}

@mixin animated-grad($time: 30) {
    background-image: linear-gradient(136degdeg, $blue 0%, $grad-stop 17%, $red 35%, $pink 51%, $patriarch 68%, $purple-heart 83%, $blue 99%);
    background: linear-gradient(136deg, $blue, $red, $blue);
    background-size: 600% 600%;
    animation: BackgroundChange #{$time}s ease infinite;
}

@keyframes BackgroundChange {
    0% {
        background-position: 48% 0%;
    }

    50% {
        background-position: 53% 100%;
    }

    100% {
        background-position: 48% 0%;
    }
}

// For IE8+ this is all thats required
// https://css-tricks.com/snippets/css/clear-fix/
@mixin cf {
    &:after {
        content: "";
        display: table;
        clear: both;
    }
}

// Hide from sight but accessible to screen readers
@mixin visuallyhidden {
    position: absolute;
    overflow: hidden;
    clip: rect(0 0 0 0);
    height: 1px; width: 1px;
    margin: -1px; padding: 0; border: 0;
}

//------------------------------------------------------------------------------
//         Borrowed from: http://minimalmonkey.com/sass-triangle-mixin/
//         and kind of converted from SASS to LESS, then back to SCSS
//-----------------------------------------------------------------------------
@mixin arrow($width: 20px, $height: 20px, $color: $dark-grey, $direction: down) {
    width: 0;
    height: 0;

    @if $direction == down {
        border-left: $width / 2 solid transparent;
        border-right: $width / 2 solid transparent;
        border-top: $height solid $color;
    }

    @if $direction == up {
        border-left: $width / 2 solid transparent;
        border-right: $width / 2 solid transparent;
        border-bottom: $height solid $color;
    }

    @if $direction == left {
        border-top: $height/2 solid transparent;
        border-bottom: $height/2 solid transparent;
        border-right: $width solid $color;
    }

    @if $direction == right {
        border-top: $height/2 solid transparent;
        border-bottom: $height/2 solid transparent;
        border-left: $width solid $color;
    }
}

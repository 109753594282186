.QuestionExplanation {
    background: $purple-grad;
    color: $white;

    padding: 20px 26px 28px;

    margin: 10px -16px;

    @include desktop {
        margin-left: 0;
        margin-right: 0;
        // margin: 0 -16px;
    }


    &__Body {
        margin: 0;
        padding-top: 10px;
        // padding-right: 38px;
    }

    &__Button {
        background-color: transparent;
        background-repeat: no-repeat;
        background-position: center top;
        background-size: contain;
        border: 0;
        cursor: pointer;
        float: right;

        height: 30px;
        width: 30px;
        padding: 0;

        text-indent: 9999px;
        overflow: hidden;
        white-space: nowrap;

        &--question {
            background-image: url("../img/icons/question.svg");
        }

        &--close {
            background-image: url("../img/icons/close_circle.svg");
        }
    }
}
